<template>

  <div>
    <b-container style="padding-top: 6rem;" class="mb-4">
      <b-row>
        <b-col></b-col>
        <b-col lg="8" sm="10">
          <b-card bg-variant="primary" class="mb-2" text-variant="white" title="编辑应用信息">
          </b-card>

          <b-card class="mb-2" text-variant="dark">
            <b-card-text>
              <p><b-icon-lightning variant="info"></b-icon-lightning>
                高效稳定的苹果内侧应用分发平台
              </p>
              <p><b-icon-lightning variant="info"></b-icon-lightning>
                基于TestFlight，极速生成应用体验链接
              </p>
              <p><b-icon-lightning variant="info"></b-icon-lightning>
                QQ联系我们：<span style="color: dodgerblue; font-weight: bold">253481396</span>
              </p>

            </b-card-text>

            <b-form class="mt-5 ">
              <b-form-group label-cols-sm="3"
                            label-cols-lg="2"
                            description="输入你的应用名称">
                <template slot="label">
                  应用名称
                </template>
                <b-form-textarea
                  id="edit-appName"
                  v-model="form.appName"
                  placeholder=""
                  aria-describedby="edit-appName-feedback"
                  trim
                ></b-form-textarea>
                <b-form-invalid-feedback id="edit-appName-feedback">
                  <span></span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label-cols-sm="3"
                            label-cols-lg="2"
                            description="输入你的应用描述">
                <template slot="label">
                  应用描述
                </template>
                <b-form-textarea
                  id="edit-appDesc"
                  v-model="form.appDesc"
                  rows="5"
                  placeholder=""
                  aria-describedby="edit-appDesc-feedback"
                  trim
                ></b-form-textarea>
                <b-form-invalid-feedback id="edit-appDesc-feedback">
                  <span></span>
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group label-cols-sm="3"
                            label-cols-lg="2"
                            description="一般为5张截图">
                <template slot="label">
                  上传截图
                </template>
                <FileUpalod
                  :accept="'.png, jpeg, jpg'"
                  :initScreenShots = "initScreenShots"
                  ref="fileUpload">

                </FileUpalod>
              </b-form-group>

            </b-form>
            <b-button style="float: right" type="submit" variant="primary" @click="onSubmit">提交</b-button>

          </b-card>
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
    <InfoModal ref="loginModal"></InfoModal>
  </div>

</template>

<script>

  import {empty, API_BASE_URL} from "../../../constant";
  import {ipaVersionAdd, ipaVersionUpdate} from "../../../util/APIUtils";
  import axios from 'axios'
  import { BButton, BForm, BFormFile, BFormGroup,BInputGroup,BInputGroupPrepend,
    BFormInput, BFormTextarea,BFormInvalidFeedback, BCard, BCardText, BProgress,

  } from 'bootstrap-vue'

  import FileUpalod from './components/FileUpload';
  import {ipaVersionDetail} from "../../../util/APIUtils";

  import {BIconLightning, BIcon, BIconCheck } from 'bootstrap-vue'

  export default {
    components:{
      FileUpalod,
      BButton, BForm, BFormFile, BFormGroup,BInputGroup,BInputGroupPrepend,
      BFormInput,BFormTextarea,BFormInvalidFeedback,BCard,BCardText, BProgress, BIcon, BIconCheck, BIconLightning
    },
    name: "Edit",
    data(){
      return {

        id: null,

        form:{
          appName: null,
          appDesc: null,
          screenShots: null,
        },
        initScreenShots: null,
        reg:{
          qq: /^[1-9]\d{4,9}$/,
          appName: null,
        },
        progress: 0,
        file: null,
        fileRet: null
      }
    },
    watch:{
      'file':  function  (newVale, oldValue) {
        if(Boolean(this.file)){
          const fileReader = new FileReader();
          fileReader.readAsDataURL(this.file);
          //Upload begin from below
          let fd = new FormData();
          fd.append('file',this.file);
          let config = {
            onUploadProgress: progressEvent => {
              let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
              this.progress = complete
              console.log(this.progress);
            }
          };
          axios.post(API_BASE_URL+'/api/front/upload',fd, config).then( res => {
            if( res.data.code === 0){
              this.fileRet = {
                ipaName: res.data.data.name,
                ipaPath: res.data.data.url
              };
            }
          }).catch(error => {
            console.log(error)
          })
        }
      }
    },
    computed:{
      appNameState(){
        if(!this.form.appName ){
          return null;
        }
        return empty(this.form.appName) ? true : false
      },
    },
    methods:{
      async onSubmit(){
        if(empty(this.form.appName)){
            this.$refs.loginModal.showModal('应用名称不能为空','确认');
            return;
        }

        if(this.form.appDesc){
          //this.form.appDesc = this.form.appDesc.replace('↵↵','<br>');
          //this.form.appDesc = this.form.appDesc.replace('\n','<br>');

          //this.form.appDesc = this.form.appDesc.replace('↵','&ensp;');


        }
        this.form.screenShots = JSON.stringify(this.$refs.fileUpload.getScreenShotObj());
        this.$myLoading.open('正在提交修改');
        let res = await ipaVersionUpdate(this.id ,this.form);
        this.$myLoading.hide();
        if(res.code===0){
          this.$refs.loginModal.showModal('提交成功','确认');
        }else{
          this.$refs.loginModal.showModal(res.message,'确认');
        }
      },
      async getDetail(){
        let res = await ipaVersionDetail(this.id);
        if(res.code===0){
          this.form.appName = res.data.appName;
          this.form.appDesc = res.data.appDesc;
          //this.form.appDesc = this.form.appDesc.replace('<br>', '↵↵');
          //this.form.appDesc = this.form.appDesc.replace('<br>','\n');

          //this.form.appDesc = this.form.appDesc.replace('&ensp;','↵');
          this.initScreenShots = res.data.screenShots;
          console.log(this.initScreenShots)
        }

      }
    },
    created(){
      this.id = this.$route.params.id;
      if(this.id){
        this.getDetail();
      }else{
        this.$router.push({name: 'home'})
      }
    },
    mounted() {
    }
  }
</script>

<style scoped>

  /deep/ .text-muted{
    /*color: dodgerblue !important;*/
  }
</style>
