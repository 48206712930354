<template>
  <div>
    <b-form-file
      v-model="file"
      :accept="accept"
      placeholder=""
      drop-placeholder=""
      aria-describedby="ipa-feedback"
    ></b-form-file>
    <b-form-invalid-feedback id="ipa-feedback">
      <span>请上传ipa</span>
    </b-form-invalid-feedback>
    <div class="mt-2" v-if="progress>1 && progress<99">
      <b-progress :value="progress" variant="info" :key="progress"></b-progress>
    </div>
    <div class="mt-2" v-else-if="progress>99 && progress<100">
      <b-progress :value="progress" variant="success" :key="progress"></b-progress>
    </div>
    <div class="mt-2" v-else-if="progress===100 && fileList && fileList.length">
      {{fileList.length}}个文件上传成功<b-icon-check variant="success"></b-icon-check>
    </div>
    <div class="mt-3">
      <b-card v-for="(item, index) in fileList" body-class="text-center"
              :key="index"
        style="width: 45%; display: inline-block;margin-left: 3%"
        :img-src="item.url">
          <b-button size="sm" @click="deleteImg(index)" variant="info">删除重传</b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
  import { BButton, BForm, BFormFile, BFormGroup,BInputGroup,BInputGroupPrepend,
    BFormInput, BFormTextarea,BFormInvalidFeedback, BCard, BCardText, BProgress,
    BIcon, BIconCheck, BIconLightning
  } from 'bootstrap-vue'

  import {API_BASE_URL} from "../../../../constant";
  import axios from 'axios'

  export default {
    name: "FileUpload",
    props: ['accept', 'initScreenShots'],
    components:{
      BButton, BForm, BFormFile, BFormGroup,BInputGroup,BInputGroupPrepend,
      BFormInput,BFormTextarea,BFormInvalidFeedback,BCard,BCardText, BProgress, BIcon, BIconCheck, BIconLightning
    },
    data(){
      return{
        file: null,
        progress: 0,
        fileList: [],
      }
    },
    mounted(){

    },
    watch:{
      'file':  function  (newVale, oldValue) {
        console.log(this.file);
        if(Boolean(this.file)){
          const fileReader = new FileReader();
          fileReader.readAsDataURL(this.file);
          //Upload begin from below
          let fd = new FormData();
          fd.append('file',this.file);
          let config = {
            onUploadProgress: progressEvent => {
              let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
              this.progress = complete
              console.log(this.progress);
            }
          };
          axios.post(API_BASE_URL+'/api/front/upload',fd, config).then( res => {
            if( res.data.code === 0){
              this.fileList.push( {
                name: res.data.data.name,
                url: res.data.data.url
              });
              this.file = null;
              this.progress = 0;
            }
          }).catch(error => {
            console.log(error)
          })
        }
      },
      'initScreenShots': function (newVale, oldValue){
        if(this.initScreenShots){
          try{
            this.fileList = JSON.parse(this.initScreenShots)? JSON.parse(this.initScreenShots): [];
          }catch (e) {
            console.error(e);
          }
        }
      }
    },
    methods:{
      deleteImg(index){
        this.fileList.splice(index,1);
      },
      getScreenShotObj(){
        return this.fileList;
      }
    }
  }
</script>

<style scoped>

  /deep/ .card-body {
    padding: 1px;
  }
</style>
